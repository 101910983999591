
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import {mdiDotsVertical, mdiDownload} from "@mdi/js";
import axios from "axios";
import userModule from "@/store/modules/user";

@Component
export default class EventPage extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "event",
  });
  loading:boolean = false;

  get canSubscribe(){
    return userModule.roles.some(r => ["Administrators", "Site Editors", "Event Managers"].includes(r))
  }


  axiosDownloadFile( ) {
    this.loading=true;
    return axios({
      url:`/event/${this.$route.params.id}/template/pdf/puppeteer/`,
      method: 'GET',
      responseType: 'blob',
    })
      .then(response => {
        const href = window.URL.createObjectURL(response.data);
        const anchorElement = document.createElement('a');
        anchorElement.href = href;
        anchorElement.download = this.dataSource.model.caption + ".pdf";
        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      })
      .catch(error => {
        console.log('error: ', error);
      })
      .finally(()=>{
        this.loading=false;
      });
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "События",
        to: "/manage/events/",
        exact: true,
      },
      {
        text: this.dataSource.model?.caption,
        disabled: true,
      },
    ];
  }

  icons: any = {
    dotsVertical: mdiDotsVertical,
    download:mdiDownload
  };

  routeToPage(name: string) {
    this.$router.push({
      name,
      params: {
        eventId: this.$route.params.id,
      },
    });
  }
}
